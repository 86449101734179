import MultiLanguageInput from '@/components/DSE/MultiLanguageInput';
import Select from '@/components/DSE/Select';
import MDinput from '@/components/MDinput';
import { validatorConditionalRequired, validatorRequire, validatorRequireNumber } from '@/utils/validators';
import { MessageType } from '@/utils/constants';

export function getFormDefinition(vue) {
  return {
    messageType: {
      type: Select,
      props: {
        caption: 'messageTemplate.messageType',
        valueField: 'code',
        labelField: 'description',
        loading: vue.$store.getters['option/isLoading'],
        options: vue.$store.getters['option/loadedOptions']('messageType'),
        required: true
      },
      rules: [{ validator: validatorRequireNumber }]
    },
    code: {
      type: MDinput,
      props: {
        maxlength: 64,
        required: true
      },
      rules: [{ validator: validatorRequire }]
    },
    subject: {
      type: MultiLanguageInput,
      props: {
        type: 'textarea',
        caption: 'messageTemplate.subject'
      },
      dynamicProps: form => ({ required: form.messageType === MessageType.Mail }),
      dynamicRules: form => [{ validator: validatorConditionalRequired(form.messageType === MessageType.Mail) }]
    },
    body: {
      type: MultiLanguageInput,
      props: {
        type: 'textarea',
        caption: 'messageTemplate.body'
      },
      rules: [{ validator: validatorRequire }]
    }
  };
}

export default getFormDefinition;

export function createItemFromFormData(vue, form) {
  return form;
}

export function createFormDataFromItem(vue, item) {
  return {
    ...item
  };
}
